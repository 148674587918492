.show-only-for-print {
  display: none;
  visibility: hidden;
}

@media print {

  body, body * {
    color: black;
    background-color: transparent !important;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .hide-for-print {
    display: none;
    visibility: hidden;
  }

  .show-only-for-print {
    display: inherit;
    visibility: visible;
  }

  #fudrrApp {

    #app {
      background-color: #fff;
    }

    .v-toolbar:not(.alway-show-on-print) {
      display: none;
    }

    .v-navigation-drawer {
      display: none;
    }
    
    .v-overlay, .v-overlay--active, .v-dialog {
      display: none !important;
    }

    main#fudrr_appContent {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .fudrr-bottom-bar {
      display: none;
    }

  }

  .coupon-card {
    box-shadow: none !important;
    outline: 2px dashed #000 !important;
    .v-alert {
      border-width: 0 !important;
    }
    .v-card {
      background-color: white !important;
      border: 1px solid #000;
      border-radius: 100px;
      margin-bottom: 1em;
    }
  }

}