@import "print";
// App Bar
#fudrr-main-toolbar {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  padding: 0.5rem 0;
  border-radius: 0 0 1rem 1rem;
}

// Content
#fudrr_appContent {
  padding-top: 4.25rem !important;
}

.fudrr-fw-scroller {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  > .flex {
    min-width: calc(100vw - 6rem);
    @media screen and (min-width: 640px) {
      min-width: 500px;
    }
  }

  &.fudrr-narrow {
    > .flex {
      min-width: 65vw;
      @media screen and (min-width: 640px) {
        min-width: 400px;
      }
    }
  }
}

.fudrr-lc {
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  font-weight: 700;
}

/*
  Filepond
 */
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "~filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";
@import "../js/plugins/doka/doka.min.css";

.filepond--root {
  margin-bottom: 1px;
  z-index: 1;
  font-family: system-ui, BlinkMacSystemFont, -apple-system, sans-serif;
  font-size: 1em;

  .filepond--file-status {
    .filepond--file-status-main {
      font-size: 15px;
    }

    .filepond--file-status-sub {
      font-size: 12px;
    }
  }

  .filepond--file-info {
    .filepond--file-info-main {
      font-size: 15px;
    }

    .filepond--file-info-sub {
      font-size: 12px;
    }
  }

  .filepond--panel-root {
    border-radius: 4px 4px 0 0;
  }

  .filepond--drop-label label {
    cursor: pointer;

    &::before {
      content: "\f382";
      font-family: "Font Awesome 5 Free", sans-serif;
      font-weight: 900;
      margin-right: 8px;
      color: grey;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.06);
    display: block;
    bottom: -1px;
    width: 2px;
    height: 1px;
  }

  &::after {
    bottom: -1px;
    right: 0;
  }
}

// Fallback button
#fudrrLoading {
  position: absolute;
  width: 100%;
}

/*
  VideoJS
 */
// @import "~video.js/dist/video-js.min.css";

/*
  Custom Styles
 */
video {
  width: 100%;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}

.username-link {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

@media (max-width: 768px) {
  div.caption > a.username-link {
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    max-width: 56px;
    display: inline-block;
  }
}

.fudrr-bottom-bar.notched {
  height: auto !important;

  a {
    padding-bottom: env(safe-area-inset-bottom) !important;
  }
}

#fudrr-logo {
  max-width: 100px;
  margin-bottom: 2px;

  .cls-1 {
    fill: #e62227;
  }

  .cls-2 {
    fill: #fcb316;
  }

  .cls-3 {
    fill: #259b48;
  }

  .cls-4 {
    fill: #38a4dd;
  }

  .cls-5 {
    fill: #5565ae;
  }
}

.light-logo {
  #fudrr-logo {

    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4 {
      fill: #ffffff;
    }
  }
}

@keyframes fudrr_swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.fudrr_swing {
  transform-origin: top center;
  animation-name: fudrr_swing;
}

.fudrr_animated {
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.cursor-pointer,
.clickable {
  cursor: pointer;
}

.v-list__group.v-list__group--active {
  &:before {
    display: none;
  }
}

.post-content {
  p > iframe {
    max-width: 100%;
    border: none;
  }
}

#fudrrApp {
  transition: all 0.33s ease;
  opacity: 0;
}

#fudrrApp.app-is-loading {
  opacity: 0;
}

#fudrrApp.app-is-ready {
  opacity: 1;
}

.fudrr-quiz-stepper.theme--light.v-stepper {
  background: transparent;
  box-shadow: none;
}

.fudrr-quiz-stepper .v-stepper__wrapper {
  overflow: visible;
}

.fudrr-quiz-stepper-header {
  box-shadow: none;
  background: transparent;
}

.intro-container {
  @media (max-width: 500px) {
    min-height: calc(100vh - 52px);
  }

  .title {
    text-align: center;
  }

  img {
    max-width: 100%;
    margin-bottom: 0.5em;
  }
}

.like-chip {
  margin-right: 0;

  .v-chip__content {
    padding: 0 8px;
  }
}

.like-button {
  margin-left: 0 !important;
}

.like-header {
  .v-toolbar__content {
    height: 36px !important;
  }
}

.like-list {
  .v-list__tile {
    height: 36px;
  }
}

.liked-avatar {
  min-width: 36px;
}

svg.ios-share-icon {
  max-width: 20px;
  max-height: 20px;
  vertical-align: sub;

  path {
    fill: #027BFF;
  }
}

svg.ios-add-to-homescreen-icon {
  max-width: 20px;
  max-height: 20px;
  vertical-align: middle;

  path {
    fill: #52525C;
  }
}

.search-autocomplete {
  &.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
  }
}

.text-uc-first {
  text-transform: capitalize;
}

.featured-comment-badge {
  min-width: 0;
  margin-right: 0.5em;
}

.fudrr-menu-button {
  i.v-icon--right {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 640px) {
  .v-item-group.v-bottom-nav .v-btn {
    min-width: 50px;
    padding: 8px 7px 10px;
  }
}

#wrap-logo {
  max-width: 100px;
  margin-bottom: 2px;

  .cls-1 {
    fill: #fefefe;
  }

  .cls-2 {
    fill: #1a607c;
  }

  .cls-3 {
    fill: #8cb2a0;
  }

  .cls-4 {
    fill: #3c7f53;
  }

  .cls-5 {
    fill: #3d5451;
  }

  .cls-6 {
    fill: #4b605d;
  }

  .cls-7 {
    fill: #415754;
  }

  .cls-8 {
    fill: #3f5552;
  }

  .cls-9 {
    fill: #3f5653;
  }

  .cls-10 {
    fill: #3e5452;
  }

  .cls-11 {
    fill: #405654;
  }

  .cls-12 {
    fill: #3e5451;
  }

  .cls-13 {
    fill: #3c5250;
  }

  .cls-14 {
    fill: #465c59;
  }

  .cls-15 {
    fill: #405653;
  }

  .cls-16 {
    fill: #455b58;
  }

  .cls-17 {
    fill: #435956;
  }

  .cls-18 {
    fill: #425755;
  }

  .cls-19 {
    fill: #425855;
  }

  .cls-20 {
    fill: #455a58;
  }

  .cls-21 {
    fill: #3d5351;
  }

  .cls-22 {
    fill: #485d5a;
  }

  .cls-23 {
    fill: #354d4a;
  }

  .cls-24 {
    fill: #3c5350;
  }

  .cls-25 {
    fill: #5f726f;
  }

  .cls-26 {
    fill: #798887;
  }

  .cls-27 {
    fill: #b7d9e0;
  }

  .cls-28 {
    fill: #fdfdfd;
  }

  .cls-29 {
    fill: #eceeee;
  }

  .cls-30 {
    fill: #fbfbfb;
  }

  .cls-31 {
    fill: #f8f8f8;
  }

  .cls-32 {
    fill: #f8f9f9;
  }

  .cls-33 {
    fill: #e4e7e6;
  }

  .cls-34 {
    fill: #d4d9d9;
  }
}

// Social Share Icons
.link-share {
  border: none;
  text-decoration: none;

  i {
    font-size: 3rem;
  }

  &.facebook i:before {
    color: #27439C;
  }

  &.twitter i:before {
    color: #1895EF;
  }

  &.whatsapp i:before {
    color: #50C85C;
  }

  &.instagram i:before {
    color: #fb3958;
  }

  &.snapchat {
    i {
      background: radial-gradient(circle, black 0%, black 50%, white 65%);
    }

    i:before {
      color: #FFFC00;
    }
  }
}

// Fortune CSS

@keyframes lds-wedges {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fortune {
  .lds-wedges {
    position: relative;
  }

  &.active {
    .lds-wedges > div > div {
      animation: lds-wedges 0.25s linear 5;
    }
  }

  .lds-wedges > div > div {
    transform-origin: 100px 100px;
    opacity: 0.8;
  }

  .lds-wedges > div > div > div {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 70px;
    height: 70px;
    border-radius: 70px 0 0 0;
    transform-origin: 70px 70px;
  }

  .lds-wedges > div div:nth-child(1) > div {
    background: #facd9e;
    transform: rotate(0deg);
  }

  .lds-wedges > div div:nth-child(2) > div {
    background: #389798;
    transform: rotate(90deg);
  }

  .lds-wedges > div div:nth-child(3) > div {
    background: #459448;
    transform: rotate(180deg);
  }

  .lds-wedges > div div:nth-child(4) > div {
    background: #f19d3b;
    transform: rotate(270deg);
  }

  .lds-wedges {
    width: 200px !important;
    height: 200px !important;
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  }
}
